package com.pirates9802.web

import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.ui.Modifier
import androidx.compose.ui.window.Window
import com.pirates9802.app.App
import org.jetbrains.skiko.wasm.onWasmReady

fun main() {
    onWasmReady {
        Window("Pirates9802") {
            Column(modifier = Modifier.fillMaxSize()) {
                App()
            }
        }
    }
}